// Compile directive: Allow angular to change HTML on the fly

const { highlightTerm, replaceTerm, removeTerm } = require('@src/helpers/termHelper');
const sanitize = require('sanitize-html');

module.exports = [
  '$compile',
  'routerService',
  ($compile, routerService) => {
    function sanitizeHtml(string, noBlankTarget) {
      string = sanitize(string, {
        allowedTags: [
          'b',
          'em',
          'strong',
          'a',
          'li',
          'ul',
          'ol',
          'p',
          'br',
          'table',
          'tr',
          'td',
          'tbody',
          'ws-term',
          'span',
        ],
        allowedAttributes: {
          a: ['href', 'target', 'class', 'rel'],
          li: [],
          ul: ['class'],
          table: ['class'],
          'ws-term': ['ws-href'],
        },
        allowedClasses: {
          ul: ['list-basic-disc'],
          table: ['table', 'table-striped'],
          a: ['theme-content-link'],
          span: ['keyword'],
        },
        selfClosing: ['br'],
        nonTextTags: ['style', 'script', 'textarea', 'noscript'],
        transformTags: {
          ul() {
            return {
              tagName: 'ul',
              attribs: {
                class: 'list-basic-disc',
              },
            };
          },
          table() {
            return {
              tagName: 'table',
              attribs: {
                class: 'table table-striped',
              },
            };
          },
          a(tagName, attribs) {
            if (attribs.href) {
              if (attribs.rel === 'term') {
                return {
                  tagName: 'a',
                  attribs: {
                    href: attribs.href,
                    rel: attribs.rel,
                  },
                };
              }
              if (attribs.rel === 'webpage2-link') {
                const webpageInfo = routerService.getWebpageInfoFromHref(attribs.href);
                return {
                  tagName: 'a',
                  attribs: {
                    href: webpageInfo ? webpageInfo.path : attribs.href,
                    class: 'theme-content-link',
                  },
                };
              }
              return {
                tagName: 'a',
                attribs: {
                  target: noBlankTarget ? '_self' : '_blank',
                  class: 'theme-content-link',
                  href: routerService.getValidUrl(attribs.href),
                },
              };
            }
            return {};
          },
        },
      });
      return string;
    }

    const filterHtml = (string) => {
      string = sanitize(string, {
        allowedTags: ['br', 'ul', 'ol', 'li', 'b'],
      });
      return string;
    };

    const filterHtmlExceptKeywords = (string) =>
      sanitize(string, {
        allowedTags: ['span'],
        allowedClasses: {
          span: ['keyword'],
        },
      });

    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const useTerm = scope.$eval(attrs.compileTerm);
        const noHtml = scope.$eval(attrs.compileNoHtml);
        const noBlankTarget = scope.$eval(attrs.compileNoBlankTarget);
        const onlyKeywords = scope.$eval(attrs.compileOnlyKeywords);
        let prevValue;
        scope.$watch(
          (s) => s.$eval(attrs.compile),
          (value) => {
            if (prevValue !== value) {
              if (typeof value !== 'undefined' && value !== null) {
                if (noHtml) {
                  value = filterHtml(value);
                } else if (onlyKeywords) {
                  value = filterHtmlExceptKeywords(value);
                } else {
                  value = sanitizeHtml(value, noBlankTarget);
                }
                if (useTerm) {
                  if (useTerm === 'highlight') {
                    value = highlightTerm(value);
                  } else {
                    value = replaceTerm(value);
                  }
                } else {
                  value = removeTerm(value);
                }
                element.html(value);
                $compile(element.contents())(scope);
              }
            }
          }
        );
      },
    };
  },
];
