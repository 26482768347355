import React, { useEffect, useState } from 'react';
import { stripHtml } from '@src/app/utils';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';

const DatabaseResultsItem = ({
  item,
  onUpdate,
  dbKey,
  onUpdateZill,
  references,
  zillCurricula,
  isGlobalDatabase,
}) => {
  const [zillTag, setZillTag] = useState([]);

  useEffect(() => {
    if (item.references && zillCurricula && zillCurricula.length > 0) {
      const uniqueIds = new Set();
      const zillTagArray = item.references
        .map((r) => {
          let ref = zillCurricula?.find((re) => r === `/content/${re.href}`);
          if (!ref) {
            ref = zillCurricula
              .filter((re) => re.hrefsUnder?.includes(r))
              .reduce((max, re) => (max?.level > re?.level ? max : re), null);
          }

          return ref || null;
        })
        .filter(Boolean)
        .filter((re) => {
          const isDuplicate = uniqueIds.has(re.href);
          uniqueIds.add(re.href);
          return !isDuplicate;
        });
      setZillTag(zillTagArray);
    }
  }, [zillCurricula, item.references]);

  return (
    <div className="kov-pro-bib-list-item">
      <a className="list-item-image">
        <div
          className="list-item-image-holder"
          style={{
            background: item.thumbImageS && `url(${item.thumbImageS.href})`,
          }}
        />
      </a>
      <div className="list-item-info">
        <a
          href={`${item.pagePath}${item.isShared && !isGlobalDatabase ? `?refDB=${dbKey}` : ''}`}
          className="list-item-info__title"
        >
          <h4>{stripHtml(item.title)}</h4>
        </a>
        <div className="list-item-info__excerpt">{stripHtml(item.description)}</div>
        <div className="list-item-info__tags">
          {item.requirements &&
            item.requirements.map((r) => {
              const ref = references?.find((re) => r === `/content/${re.key}`);
              return ref ? (
                <a key={ref.key} onClick={() => onUpdate(ref.key)} className="category-tag">
                  {stripHtml(ref.title)}
                </a>
              ) : null;
            })}
          {zillTag.length > 0 &&
            zillTag.map((ref) =>
              ref ? (
                <a key={ref.href} onClick={() => onUpdateZill(ref.href)} className="category-tag">
                  {ref.code}
                </a>
              ) : null
            )}
        </div>
        <ApplicabilityLabels labels={item.tags} />
      </div>
    </div>
  );
};

export default DatabaseResultsItem;
