import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Banner from '@src/reactComponents/sections/banner/banner';
import { ThemeMenu } from '@components/database/components';
import useFaq from '@components/theme/themeFaq/hooks/useFaq';
import { FaqFilters, FaqResults } from '@components/theme/themeFaq/components';
import NewsSection from '../newsSection/newsSection';
import useLinks from '../hooks/useLinks';

const ThemeFaq = ({ page, sTheme, locationPath }) => {
  const domContainerRef = useRef(null);
  useLinks(page, domContainerRef);
  // this is the search term that is used to update all the filters because, when this is set
  // it means that the user clicked on the search button
  const [searchedTerm, setSearchedTerm] = useState('');
  // this search term is updated everytime an user types something in the
  // inputSearch (this state belongs to the input Search component..)
  const [temporalSearchTerm, setTemporalSearchTerm] = useState('');
  const [numFiltersApplied, setNumFiltersApplied] = useState(0);
  const [filtersSidebarOpen, setFiltersSidebarOpen] = useState(false);

  const faqGroupAllHref = useMemo(() => page.href, [page]);
  const [selectedFaqGroupHref, setSelectedFaqGroupHref] = useState(faqGroupAllHref);
  const [curricula, setCurricula] = useState([]);
  const [scrollTo, setScrollTo] = useState('');
  const scrollToItem = useCallback((scrollPoint) => {
    setTimeout(() => {
      if (scrollPoint) {
        const item = document.getElementById(scrollPoint);
        if (item) {
          item.scrollIntoView({ block: 'center' });
        }
      }
    }, 300);
  }, []);

  const handleStateUpdateFromUrl = useCallback(
    (input) => {
      if (input.key === 'q') {
        setTemporalSearchTerm(input.value);
        setSearchedTerm(input.value);
      }
      if (input.key === 'faqGroup') {
        setSelectedFaqGroupHref(input.value);
      }
      if (input.key === 'scrollTo') {
        setScrollTo((prevValue) => {
          if (input.value === '') return prevValue;
          // The useFaq hook will trigger the change on the url to remove the param from it
          // Just when this clean-up thing happen it means we already finished fetching the results
          // Only after that we trigger the browser scroll. This is needed to ensure that the items
          // are in the screen
          scrollToItem(prevValue);
          return input.value;
        });
      }
      if (input.key === 'curricula') {
        setCurricula(input.value);
      }
    },
    [scrollToItem]
  );

  const { faqGroups, filteredResults } = useFaq(
    page,
    searchedTerm,
    curricula,
    selectedFaqGroupHref,
    handleStateUpdateFromUrl,
    scrollTo
  );

  useEffect(() => {
    setNumFiltersApplied((prev) => {
      if (prev === 2) return selectedFaqGroupHref !== faqGroupAllHref ? 2 : 1;
      return selectedFaqGroupHref !== faqGroupAllHref ? 1 : 0;
    });
  }, [selectedFaqGroupHref, faqGroupAllHref]);

  return faqGroups ? (
    <>
      <Banner menuItem={page} theme={sTheme} onUpdateSubCurricula={setCurricula} />

      <section id="theme-page">
        <ThemeMenu theme={sTheme} items={sTheme.children} current={page.href} />
        <div className="container">
          <div className="kov-pro-theme-main-content-title col-12 d-none d-md-block">
            <h1>{page.title}</h1>
          </div>
          {page.description && (
            <p
              className="leadtext leadtext--longread col-12"
              dangerouslySetInnerHTML={{ __html: page.description }}
            />
          )}
          <div
            className="kov-pro-theme-main-content kov-pro-faq kov-pro-faq--numbered row"
            id={'faq'}
            ref={domContainerRef}
          >
            <FaqFilters
              faqGroups={faqGroups}
              selectedFaqGroupHref={selectedFaqGroupHref}
              sTheme={sTheme}
              onUpdateSelectedFaqGroup={setSelectedFaqGroupHref}
              searchTerm={temporalSearchTerm}
              onUpdateSearchTerm={setTemporalSearchTerm}
              onSearch={() => setSearchedTerm(temporalSearchTerm)}
              numFiltersApplied={numFiltersApplied}
              filtersSidebarOpen={filtersSidebarOpen}
              setFiltersSidebarOpen={setFiltersSidebarOpen}
            />
            <FaqResults
              searchTerm={temporalSearchTerm}
              searchResults={filteredResults}
              onUpdateSearchTerm={setTemporalSearchTerm}
              onSearch={() => setSearchedTerm(temporalSearchTerm)}
              sTheme={sTheme}
              scrollTo={scrollTo}
              numFiltersApplied={numFiltersApplied}
              filtersSidebarOpen={filtersSidebarOpen}
              setFiltersSidebarOpen={setFiltersSidebarOpen}
            />
          </div>
        </div>
        {sTheme.referenceFrameItem && (
          <NewsSection
            sReferenceFrame={sTheme.referenceFrameItem}
            sThemePath={sTheme.pagePath}
            locationPath={locationPath}
          />
        )}
      </section>
    </>
  ) : null;
};

ThemeFaq.propTypes = {
  sTheme: PropTypes.object,
  page: PropTypes.object,
  locationPath: PropTypes.string,
};

export default ThemeFaq;
