import {
  calcSecurityLevel,
  getSecurityStatusFromSecurityLevel,
  inferForbiddenItemSecurityFromParent,
  getSecurityStatus,
} from '@src/helpers/accessHelper';
import { getAllItemsBySource } from '@src/services/sitemapService';

import { isPreview } from '@src/helpers/previewHelper';
import settings from '@src/config/settings';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { isVisibleWithViewOptions } from './viewOptionsHelper';

const uuid = require('uuid');
const contentUtils = require('@src/app/contentUtils');
const utils = require('../app/utils');
const constants = require('../components/constants');

/**
 * Finds a menuItem that references the pro theme. A pro theme could be referenced
 * for more than one menuItems. The user's navigation history will help us to idenfity which one to choose.
 * In case we dont find any match in the user's navigation history, the first menuItem in the navTree
 * referecing the theme is returned.
 * @param {*} navTree: menuItems
 * @param {*} theme: the pro theme
 * @returns menuItem referencing the theme
 */
function findThemeInNavTree(navTree, theme) {
  const navItems = utils.flattenTree(navTree, false);

  const menuItemsReferencingTheme = navItems.filter(
    (menuItem) => menuItem?.theme?.href === theme.href
  );

  if (menuItemsReferencingTheme.length === 0) {
    return null;
  }

  const routerService = getAngularService('routerService');
  return menuItemsReferencingTheme.length > 1
    ? routerService.checkPreviousPaths(menuItemsReferencingTheme)
    : menuItemsReferencingTheme[0];
}

/**
 * Gets the list of ancestors of theme, taking into account the
 * user's navigation history for theme appearing more than once in the navigation tree
 * @param {*} navTree: menuItem tree
 * @param {*} theme: theme to find
 * @param {*} topHref: the navTree contains the menuItem href root.
 * @returns the list of ancestors of a theme.
 */
export function getThemeAncestors(navTree, theme, topHref) {
  let menuItem = findThemeInNavTree(navTree, theme);

  /*
      If the menuItem is undefined, it means it was not found in the navTree. This can happen because it is not linked to the reference frame.
      By returning an empty array, no item will be selected in the quickNavigation.
     */

  if (!menuItem) return [];

  const flatList = [];
  while (menuItem?.href !== topHref) {
    flatList.push(menuItem);
    menuItem = menuItem.parent;
  }

  flatList.reverse();

  return flatList;
}

export function getPageShareableImage(page) {
  const image = page?.openGraphThumbImage || page?.openGraphCoverImage;

  if (!image && page?.parent) {
    return getPageShareableImage(page.parent);
  }

  return image || constants.HOMEPAGE_COVER_IMAGE;
}

export function isThemeValid(themeTree) {
  return themeTree && (isPreview() || settings.allowNotPublished || themeTree.issued);
}

function addTrainings(theme, des) {
  let description = 'Overzicht van nascholingen, vormingen, netwerken …';

  if ((des === '' || des == null) && theme.trainingsAdded) {
    description = '';
  }

  return {
    key: uuid.v4(),
    referenceFrameItem: theme.referenceFrameItem,
    pagePath: `${theme.pagePath}/professionalisering`,
    type: 'SECTION',
    href: 'training',
    pageType: 'THEME_TRAINING',
    visible: true,
    title: 'Professionalisering',
    description: true,
    shortDescription: description,
    mainstructuresOuTypeCombinations: theme.mainstructuresOuTypeCombinations,
    coverage: theme.coverage,
  };
}

function getContactDetails(foundItem) {
  const contacts = foundItem.contacts ? [...foundItem.contacts] : [];
  const hardcoded = require('@src/config/contacts');
  const foundContacts = hardcoded.filter((o) => o.content === foundItem.$$meta.permalink);
  foundContacts.forEach((o) => contacts.push(o.details));
  return contacts;
}

function calcTypeFromParent(parent) {
  if (parent.pageType === 'MINI_DATABASE') {
    return 'MINI_DATABASE_ITEM';
  }
  if (parent.pageType === 'BLOG') {
    return 'BLOG_ITEM';
  }
  if (parent.pageType === 'THEME_HOME') {
    return 'THEME_DETAIL';
  }

  return null;
}

function calcType(href, level, parent) {
  const routerService = getAngularService('routerService');
  const webpage = routerService.getWebpageInfoFromHref(href);
  if (level === 0) {
    if (webpage) {
      switch (webpage.type) {
        case 'MINI_DATABASE':
        case 'BLOG':
        case 'TEMPORARY_PAGE':
        case 'DOWNLOAD_PAGE':
          return webpage.type;
        case 'THEME_HOME_PICTURE':
        case 'THEME_HOME_TEXT':
        case 'THEME_HOME_FULL':
          return 'THEME_HOME';
        default:
          // case 'THEME_PAGE':
          return 'THEME_DETAIL_ROOT';
      }
    } else {
      // console.warn('no type: ' + href);
      return null;
    }
  }
  if (level === 1) {
    if (webpage) {
      switch (webpage.type) {
        case 'MINI_DATABASE':
        case 'BLOG':
        case 'MINI_DATABASE_ITEM':
        case 'BLOG_ITEM':
        case 'CURRICULUM_PAGE':
          return webpage.type;
        default:
          // case 'THEME_PAGE':
          return 'THEME_DETAIL';
      }
    } else {
      return calcTypeFromParent(parent);
    }
  }
  if (level > 1) {
    return calcTypeFromParent(parent);
  }

  return null;
}

function calcPath(item) {
  /**
   * The Path Calculation Logic
   * a theme section's url is either its href or a translated url from the webpages
   * a theme section can have a REFERENCE to another page/theme/external url/pro url/...
   * the REFERENCE relation overwrites the url (it is leading)
   * the REFERENCE relation url can also have a url in the webpages. in that case, use the webpage url instead of the href.
   *
   * EXCEPTION:
   * for MINI_DATABASE_ITEM the REFERENCES relation is used for something else (someone explain?!). so we can NOT follow the relation.
   */

  let href = item.$$meta.permalink;
  let isPathExternal = false;
  const referencesRelation = item.$$relationsFrom.find(
    (e) => e.$$expanded.relationtype === 'REFERENCES'
  );
  const routerService = getAngularService('routerService');
  let webpage = routerService.getWebpageInfoFromHref(href);

  if (
    item.type !== 'STRUCTURED_DOCUMENT' &&
    referencesRelation &&
    (!webpage || (webpage && webpage.type !== 'MINI_DATABASE_ITEM'))
  ) {
    // follow the REFERENCES relation ALWAYS, EXCEPT when the webpage is a MINI_DATABASE_ITEM
    href = referencesRelation.$$expanded.to.href;
    webpage = routerService.getWebpageInfoFromHref(href);
  }

  const pagePath = webpage ? webpage.path : href;
  try {
    if (pagePath.startsWith('http')) {
      const url = new URL(pagePath);
      if (window.location.host !== url.host) {
        isPathExternal = true;
      }
    }
  } catch (e) {
    /* empty */
  }

  return {
    pagePath,
    isPathExternal,
  };
}

function calcFacets(href) {
  const routerService = getAngularService('routerService');
  const webpage = routerService.getWebpageInfoFromHref(href);
  return webpage ? webpage.facets : null;
}

function getRegions(foundItem) {
  if (!foundItem.coverage || foundItem.coverage.length === 5) {
    return [];
  }

  return constants
    .getRegions()
    .filter((region) => foundItem.coverage.includes(region.href))
    .map((region) => region.tag);
}

function getEducationLevels(foundItem) {
  if (!foundItem.mainstructuresOuTypeCombinations) return [];

  return constants
    .getEducationLevels()
    .filter((level) =>
      foundItem.mainstructuresOuTypeCombinations.includes(level.mainstructuresOuTypeCombination)
    )
    .map((level) => level.tag);
}

function computeFilteringMetadata(item, parent = {}) {
  const coverage = !item.coverage || item.coverage.length === 0 ? parent.coverage : item.coverage;
  const outypes = !item.outypes || item.outypes.length === 0 ? parent.outypes : item.outypes;

  let mainstructures = [];

  if (outypes?.includes('SCHOOL')) {
    mainstructures =
      !item.mainstructures || item.mainstructures.length === 0
        ? parent.mainstructures
        : item.mainstructures;
  }

  const mainstructuresOuTypeCombinations =
    !item.mainstructuresOuTypeCombinations || item.mainstructuresOuTypeCombinations.length === 0
      ? parent.mainstructuresOuTypeCombinations
      : item.mainstructuresOuTypeCombinations;

  return [coverage, outypes, mainstructures, mainstructuresOuTypeCombinations];
}

function isTaggedWithAnyGlobalDatabase(itemThemesHrefs) {
  return itemThemesHrefs.some((itemThemeHref) =>
    constants.GLOBAL_DATABASES_THEMES_HREFS.includes(itemThemeHref)
  );
}

function addChildren(parent, $$relationsTo, level, allItems, viewOptions) {
  const isPartOfRelations = $$relationsTo.filter((o) =>
    ['IS_PART_OF', 'IS_INCLUDED_IN'].includes(o.$$expanded.relationtype)
  );
  if (isPartOfRelations.length > 0) {
    parent.children = [];
    isPartOfRelations.map(async (relation) => {
      let themeItem;
      const child = allItems[relation.$$expanded.from.href];
      if (child) {
        const securityLevel = calcSecurityLevel(child);
        const descendantsAccessRights =
          child.descendantsAccessRights || parent.descendantsAccessRights;
        const useCachedAttachments = !contentUtils.isShieldedContent(descendantsAccessRights);
        const thumbImageM = contentUtils.getImageWithSize(
          child,
          'THUMBNAIL',
          {
            w: 500,
            h: 360,
          },
          null,
          useCachedAttachments
        );
        const regionTags = getRegions(child);
        const educationLevelTags = getEducationLevels(child);
        const [coverage, outypes, mainstructures, mainstructuresOuTypeCombinations] =
          computeFilteringMetadata(child, parent);
        const { pagePath, isPathExternal } = calcPath(child);
        themeItem = {
          level,
          parent,
          href: child.$$meta.permalink,
          accessRights: child.accessRights,
          descendantsAccessRights,
          securityLevel,
          securityStatus: getSecurityStatus(child),
          pageType: calcType(child.$$meta.permalink, level, parent),
          path: pagePath, // sorry for this.. there is no real convention for which to use and there was one component checking path instead of pagePath
          pagePath,
          isPathExternal,
          facets: calcFacets(child.$$meta.permalink),
          key: child.key ? child.key : null,
          readOrder: relation.$$expanded.readorder,
          type: child.type ? child.type : null,
          title: child.title ? utils.stripHtml(child.title) : null,
          shortDescription: child.shortdescription ? child.shortdescription : null,
          description: child.description ? child.description : null,
          startAge: child.startage ? child.startage : null,
          endAge: child.endage ? child.endage : null,
          $$html: child.$$html ? child.$$html : null,
          creators: child.creators ? child.creators : null,
          identifier:
            child.identifiers && child.identifiers.length > 0 ? child.identifiers[0] : null,
          coverImage: contentUtils.getImageWithSize(
            child,
            'COVER_IMAGE',
            { w: 2000, h: 1600 },
            parent.coverImage,
            useCachedAttachments
          ),
          theme: child.themes && child.themes.length > 0 ? child.themes : null,
          thumbImage: contentUtils.getImage(child, 'THUMBNAIL'),
          thumbImageM,
          thumbImageS: contentUtils.getImageWithSize(
            child,
            'THUMBNAIL',
            { w: 100, h: 100 },
            null,
            useCachedAttachments
          ),
          openGraphCoverImage: contentUtils.getImageWithSize(
            child,
            'COVER_IMAGE',
            {
              w: 2000,
              h: 1600,
            },
            null,
            useCachedAttachments
          ),
          openGraphThumbImage: thumbImageM,
          image: contentUtils.getImage(child, 'ILLUSTRATION'),
          imageS: contentUtils.getImageWithSize(
            child,
            'ILLUSTRATION',
            {
              w: 300,
            },
            null,
            useCachedAttachments
          ),
          imageM: contentUtils.getImageWithSize(
            child,
            'ILLUSTRATION',
            {
              w: 800,
              h: 1000,
            },
            null,
            useCachedAttachments
          ),
          videoPlayback: contentUtils.calcVideoPlayback(child),
          attachment: contentUtils.getAttachment(child, ['ATTACHMENT', 'CONTENT']),
          references: contentUtils.addReferences(child),
          requirements: contentUtils.addRequirements(child),
          contacts: getContactDetails(child),
          links: contentUtils.addReferences(child),
          importance: child.importance ? child.importance : 'MEDIUM',
          externalReference: contentUtils.calcExternalLink(child),
          created: child.$$meta.created,
          issued: child.issued,
          regionTags: getRegions(child),
          educationLevelTags: getEducationLevels(child),
          tags: regionTags.concat(educationLevelTags),
          coverage,
          mainstructures,
          outypes,
          mainstructuresOuTypeCombinations,
          visible: isVisibleWithViewOptions(child, viewOptions),
        };

        // visibility needs to be added later because it needs "coverage" and "mainstructuresOuTypeCombinations" properties to be set
        themeItem = {
          ...themeItem,
          visible: isVisibleWithViewOptions(themeItem, viewOptions),
        };
        addChildren(themeItem, child.$$relationsTo, level + 1, allItems, viewOptions);
      } else if (parent.type === 'ATTACHMENTS_GROUP') {
        const accessRights = inferForbiddenItemSecurityFromParent(parent);

        themeItem = {
          // @todo this needs to be refactored at some point.
          // We are forcing the type to be 'UNSTRUCTURED_DOCUMENT' for permission issues. We are not aware at this moment
          // This is only used for access permissions. See accessHelper.js
          type: 'UNSTRUCTURED_DOCUMENT',
          level,
          parent,
          href: relation.$$expanded.from.href,
          readOrder: relation.$$expanded.readorder,
          accessRights: [accessRights],
          securityLevel: accessRights,
          securityStatus: getSecurityStatusFromSecurityLevel(accessRights),
          coverage: parent.coverage,
          outypes: parent.outypes,
          mainstructures: parent.mainstructures,
          mainstructuresOuTypeCombinations: parent.mainstructuresOuTypeCombinations,
        };

        // visibility needs to be added later because it needs "coverage" and "mainstructuresOuTypeCombinations" properties to be set
        themeItem = {
          ...themeItem,
          visible: isVisibleWithViewOptions(themeItem, viewOptions),
        };
      }

      if (themeItem) {
        parent.children.push({
          ...themeItem,
        });
      }
    });
    parent.children.sort((a, b) => a.readOrder - b.readOrder);
  } else if (parent?.theme?.length && isTaggedWithAnyGlobalDatabase(parent.theme)) {
    // we will only be getting here in the case of a MINI_DATABASE tagged with a "global_database" theme that doesn't have any "MINI_DATABASE_ITEM" on it. So we assign an empty array to be able to add "SHARED_MINI_DATABASE_ITEMS" later on
    parent.children = [];
  }
}

export function buildThemeFromItemList(
  rootHref,
  allItems,
  navTreeItems,
  preVisitedMenuItem,
  viewOptions
) {
  try {
    let themeTree;
    const root = allItems[rootHref];
    if (!root) return {};

    const type = calcType(root.$$meta.permalink, 0, null);

    const { pagePath, isPathExternal } = calcPath(root);

    const defaultMenuItem = {
      color: settings.defaultColor,
      icon: {
        contentType: 'image/svg+xml',
        description: null,
        href: settings.defaultIcon,
        rightsHolder: null,
      },
    };

    const homepageReference = navTreeItems.find((I) => I.references?.includes(rootHref));
    let menuItem = homepageReference || defaultMenuItem;

    if (preVisitedMenuItem) {
      console.log('alternate menu item found');
      menuItem = preVisitedMenuItem;
    }
    const [coverage, outypes, mainstructures, mainstructuresOuTypeCombinations] =
      computeFilteringMetadata(root);
    const coverImage = contentUtils.getImageWithSize(root, 'COVER_IMAGE', { w: 2000, h: 1600 });
    themeTree = {
      href: root.$$meta.permalink,
      pageType: type,
      path: pagePath, // sorry for this.. there is no real convention for which to use and there was one component checking path instead of pagePath
      pagePath,
      isPathExternal,
      descendantsAccessRights: root.descendantsAccessRights,
      securityLevel: calcSecurityLevel(root),
      securityStatus: getSecurityStatus(root),
      facets: calcFacets(root.$$meta.permalink),
      key: root.key,
      title: utils.stripHtml(root.title),
      tags: root.tags,
      issued: root.issued && new Date(root.issued) < new Date(),
      shortDescription: root.shortdescription ? root.shortdescription : null,
      description: root.description ? root.description : null,
      coverImage,
      openGraphCoverImage: coverImage,
      openGraphThumbImage: contentUtils.getImageWithSize(root, 'THUMBNAIL', { w: 500, h: 360 }),
      contacts: getContactDetails(root),
      hasTrainings: root.hasTrainings,
      root: {
        color: menuItem ? menuItem.color : defaultMenuItem.color,
        icon: menuItem ? menuItem.icon : defaultMenuItem.icon,
        title: root.title,
      },
      references: contentUtils.addReferences(root),
      requirements: contentUtils.addRequirements(root),
      created: root.$$meta.created,
      children: [],
      coverage,
      mainstructures,
      outypes,
      mainstructuresOuTypeCombinations,
      visible: isVisibleWithViewOptions(root, viewOptions),
      level: 0,
      referenceFrameItem: root.referenceFrameItem,
    };
    addChildren(themeTree, root.$$relationsTo, 1, allItems, viewOptions);

    const themePages = getAllItemsBySource(themeTree.href);
    if (themePages.some((page) => page.type === 'LOCAL_TRAINING')) {
      // Here, depending on the page structure, we are going to add the trainings in one place or the other
      // This is a kind of a patch because the data is not standardized.
      // The rule we follow is:
      //  - if none of the children of the ThemeTree has a pageType: it means it has no webconfiguration. That means it is a part of the main document (as a paragraph) instead of having an "entity" by itself. In this case we add the trainings at the same level of all the other children. The reason we do it like this is because:
      //    - we want to be able to show two tabs (the proTheme itself + the trainings)
      //    - we don't want to show the "trainings" children inside of the theme children (together with the paragraphs)
      //  - else : we push the "trainings" as a new children because, in this case, all children will have his own visualization (they all have a webpage so they have a path to be shown) and the trainings will just be shown as an extra tab / page
      if (!themeTree.children.some((a) => a.pageType)) {
        themeTree = {
          ...themeTree,
          children: [themeTree, addTrainings(themeTree, root.shortdescription)],
        };
      } else {
        themeTree.children.push(addTrainings(themeTree, root.shortdescription));
      }
    }
    return themeTree;
  } catch (e) {
    console.error(e);
    return null;
  }
}
