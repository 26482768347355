import { useReducer, useMemo, useEffect } from 'react';
import useUrlSearchParams from '@src/hooks/useUrlSearchParams';
import { transformViewOptionsToParams, useSearchApi } from '@components/searchApi';
import { useSelector } from 'react-redux';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { isPreview } from '@src/helpers/previewHelper';
import { SEARCH_API_LIMIT_MAX } from '@src/components/constants';
import { getFacetCategories } from '@src/reduxStore/content/contentHelpers';
import {
  parseAttachmentGroups,
  hasSecureItems,
  themeHasSharedAttachments,
} from '../helpers/themeDownloadHelpers';

const initialState = {
  loading: true,
  loadingFilters: false,
  hasSharedAttachments: undefined,
  numFiltersApplied: 0,
  results: [],
  categories: [],
  filters: {
    q: '',
    requirements: '',
    curricula: '',
  },
};

function downloadReducer(state, action) {
  switch (action.type) {
    case 'setContent': {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case 'setLoading': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'setLoadingFilters': {
      return {
        ...state,
        loadingFilters: action.payload,
      };
    }

    case 'setFilter': {
      return {
        ...state,
        filters: { ...state.filters, [action.fieldName]: action.payload },
      };
    }
    case 'setHasSharedAttachments': {
      return {
        ...state,
        hasSharedAttachments: action.payload,
      };
    }
    default:
      return state;
  }
}
const useDownloads = (downloadPage) => {
  const [state, dispatch] = useReducer(downloadReducer, initialState);
  const viewOptions = useSelector(selectUserViewOptions);

  const previewDataToUse = useMemo(
    () =>
      isPreview() ? downloadPage.children.filter((o) => o.type === 'ATTACHMENTS_GROUP') : null,
    [downloadPage.children]
  );

  const { getSearchResults, searchResults, isLoading } = useSearchApi({
    previewDataToUse,
  });

  const filterObjectIntoParamStateObj = useMemo(
    () =>
      Object.entries(state.filters).map(([key, value]) => ({
        key,
        state: value,
        updateState: (val) => {
          dispatch({ type: 'setFilter', fieldName: key, payload: val });
        },
      })),
    [dispatch, state.filters]
  );

  const { updateUrlSearchParams, urlParamsProcessed } = useUrlSearchParams(
    filterObjectIntoParamStateObj,
    true
  );

  useEffect(() => {
    if (urlParamsProcessed) updateUrlSearchParams();
  }, [state.filters, updateUrlSearchParams, urlParamsProcessed]);

  useEffect(() => {
    if (state.hasSharedAttachments !== undefined) {
      dispatch({ type: 'setLoading', payload: isLoading });
    }
  }, [isLoading, state.hasSharedAttachments]);

  const numberOfFiltersToApply = useMemo(() => {
    let numFiltersAppliedResult = 0;
    numFiltersAppliedResult += state.filters.q.length > 0 ? 1 : 0;
    numFiltersAppliedResult += state.filters.requirements !== '' ? 1 : 0;
    numFiltersAppliedResult +=
      state.filters.curricula && state.filters.curricula.length > 0 ? 1 : 0;
    return numFiltersAppliedResult;
  }, [state.filters]);

  // initialise filter facets
  useEffect(() => {
    const getCategories = async () => {
      const downloadCategories = await getFacetCategories(
        downloadPage.facets.find((o) => o.component === 'SELECT_FROM_REFERENCE_FRAME').source.href
      ).then((facetCatsResp) =>
        facetCatsResp.map((c) => ({
          href: c.$$meta.permalink,
          title: c.title,
        }))
      );

      return [
        {
          href: '',
          title: 'Alle',
        },
        ...downloadCategories,
      ];
    };

    const initialiseFilters = async () => {
      const categoryFilter = !!downloadPage.facets.find(
        (o) => o.component === 'SELECT_FROM_REFERENCE_FRAME'
      );
      if (categoryFilter) {
        const categories = await getCategories();
        dispatch({ type: 'setContent', fieldName: 'categories', payload: categories });
      }
      dispatch({ type: 'setLoadingFilters', payload: false });
    };

    if (downloadPage?.facets) {
      dispatch({ type: 'setLoadingFilters', payload: true });
      initialiseFilters();
    }
  }, [downloadPage]);

  useEffect(() => {
    const setHasSharedAttachments = async () => {
      const hasSharedAttachments = await themeHasSharedAttachments(downloadPage.href);
      dispatch({ type: 'setHasSharedAttachments', payload: hasSharedAttachments });
    };
    setHasSharedAttachments();
  }, []);

  // issue the search to the searchAPI hook and check number of filters applied for mobile
  useEffect(() => {
    if (!viewOptions || state.hasSharedAttachments === undefined) return;

    const searchApiParams = {
      types: 'pro_attachment',
      limit: SEARCH_API_LIMIT_MAX,
      q: state.filters.q,
      expand: 'FULL',
      attachmentTypes: 'DOWNLOAD',
      includedIn: downloadPage.href,
      // This doesn't seem to be working for subatomic curricula
      ...(state.filters.curricula != null ? { noThemeOrThemeRoots: state.filters.curricula } : []),
      'facets.references': state.filters.requirements,
      ...transformViewOptionsToParams(downloadPage, viewOptions),
      ...(!state.hasSharedAttachments && { orderby: 'readOrderOnDownloadPage' }),
    };

    dispatch({
      type: 'setContent',
      fieldName: 'numFiltersApplied',
      payload: numberOfFiltersToApply,
    });

    getSearchResults(searchApiParams);
  }, [
    state.filters,
    state.hasSharedAttachments,
    getSearchResults,
    downloadPage,
    numberOfFiltersToApply,
    viewOptions,
  ]);

  // update the results list when searchAPI hook returns results
  useEffect(() => {
    if (searchResults?.length > 0) {
      let attachmentGroups;
      if (isPreview()) {
        attachmentGroups = searchResults.filter((child) => child.type === 'ATTACHMENTS_GROUP');
      } else {
        attachmentGroups = parseAttachmentGroups(searchResults, downloadPage).sort(
          (a, b) => a.readOrder - b.readOrder
        );
      }

      dispatch({ type: 'setContent', fieldName: 'results', payload: attachmentGroups });
    } else {
      dispatch({ type: 'setContent', fieldName: 'results', payload: [] });
    }
  }, [searchResults, downloadPage]);

  return {
    state,
    dispatch,
    showForbiddenPlaceholder: hasSecureItems(
      downloadPage.children?.filter((o) => o.type === 'ATTACHMENTS_GROUP')
    ),
  };
};

export default useDownloads;
