module.exports = [
  () => ({
    restrict: 'A',
    scope: false,
    link(scope, element, attrs) {
      const parentId = element.children().attr('id');
      const buttons = element.find('.btn.btn-link[data-toggle="collapse"]');
      const descriptions = attrs.accordionTag
        ? element.find(`div.collapse.${attrs.accordionTag}`)
        : element.find(`div.collapse[data-parent="#${parentId}"]`);

      buttons.click(function onAccordionClick() {
        const targetId = this.getAttribute('data-target');
        const isCollapsed = this.classList.contains('collapsed');
        this.classList.toggle('collapsed');

        descriptions.each((i, desc) => {
          desc.classList.remove('show');

          const id = desc.getAttribute('id');
          if (targetId === `#${id}` && isCollapsed) desc.classList.add('show');
        });

        buttons.each((i, btn) => {
          if (btn.getAttribute('data-target') !== targetId) btn.classList.add('collapsed');
        });
      });
    },
  }),
];
