const re =
  /<a href="\/content\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"(?:(?!<\/?a).)*rel="term"(?:(?!<\/?a).)*>((?:(?!<\/?a).)*)<\/a>/gi;

export const replaceTerm = (text) => {
  if (text) {
    return text.replace(re, '<ws-term s-key="\'$1\'" s-word="\'$2\'"></ws-term>');
  }
  return undefined;
};

export const removeTerm = (text) => {
  if (text) {
    return text.replace(re, '$2');
  }
  return undefined;
};

export const highlightTerm = (text) => {
  if (text) {
    return text.replace(
      re,
      '<ws-term s-key="\'$1\'" s-word="\'$2\'" s-mode="highlight"></ws-term>'
    );
  }

  return undefined;
};
