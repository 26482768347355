import { useEffect, useState } from 'react';
import * as settings from '@src/config/settings';
import { flattenTree, hrefsUnder } from '@src/app/utils';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';

const HookStatus = {
  INITIAL: 'INITIAL',
  PENDING: 'PENDING',
};

let zillCurriculaHookStatus = HookStatus.INITIAL;

const useZillCurricula = (databaseFacets) => {
  const shouldFetchZillCurricula = databaseFacets.some(
    (facet) => facet.component === 'ZILL_GOAL_SELECTOR'
  );
  const [zillCurricula, setZillCurricula] = useState(null);
  const curriculumManager = getAngularService('curriculumManager');

  useEffect(() => {
    let isCancelled = false;
    if (shouldFetchZillCurricula) {
      const getZillCurricula = async () => {
        const zillCurriculum = await curriculumManager.prepareCurriculum(
          null,
          null,
          settings.apisAndUrls.cachedContentApi
        );
        hrefsUnder(curriculumManager, zillCurriculum);
        const { clusters } = zillCurriculum;
        const zillCurriculumObject = {
          href: 'zillCurriculum',
          children: clusters.flatMap((cluster) =>
            cluster.fields.map((field) => {
              const zillLevelViewModel = (level, depth, parent) => ({
                hrefsUnder: level.$$hrefsUnder,
                color: field.color,
                iconUrl: field.iconUrl,
                code: level.code,
                title: level.title,
                href: level.key,
                level: depth,
                parent,
              });

              const themeChildren = field.themes.map((theme) => ({
                children: theme.goals.map((goal) => ({
                  ...zillLevelViewModel(goal, 2, theme.key),
                })),
                ...zillLevelViewModel(theme, 1, field.key),
              }));

              return {
                children: themeChildren,
                ...zillLevelViewModel(field, 0, null),
              };
            })
          ),
        };

        const curriculumArray = flattenTree(zillCurriculumObject, false);

        if (!isCancelled) {
          setZillCurricula(curriculumArray);

          // updating the status back to INITIAL when the fetching finished
          zillCurriculaHookStatus = HookStatus.INITIAL;
        }
      };

      if (zillCurricula == null && zillCurriculaHookStatus === HookStatus.INITIAL) {
        zillCurriculaHookStatus = HookStatus.PENDING;
        getZillCurricula();
      }
    }
    return () => {
      zillCurriculaHookStatus = HookStatus.INITIAL;
      isCancelled = true;
    };
  }, [curriculumManager, zillCurricula, shouldFetchZillCurricula]);

  return zillCurricula;
};

export default useZillCurricula;
