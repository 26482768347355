import { DEFAULT_SANITIZE_OPTIONS } from '@kathondvla/react-shared-components/src/helpers/compilerHelper';
import { DEFAULT_OPTIONS } from '@kathondvla/react-shared-components/src/hooks/useCompiler';

const getCompilerOptions = () => ({
  ...DEFAULT_OPTIONS,
  term: {
    ...DEFAULT_OPTIONS.term,
    compile: true,
    onClick: (e) => {
      window.dispatchEvent(new CustomEvent('openTermModal', { detail: e }));
    },
  },
  sanitizeOptions: {
    ...DEFAULT_SANITIZE_OPTIONS,
    allowedTags: [...DEFAULT_SANITIZE_OPTIONS.allowedTags, 'sub', 'sup'],
    allowedAttributes: {
      ...DEFAULT_SANITIZE_OPTIONS.allowedAttributes,
      a: ['href', 'target', 'rel', 'class', 'data-link'],
    },
    transformTags: {
      ...DEFAULT_SANITIZE_OPTIONS.transformTags,
      a(tagName, attribs) {
        if (!attribs.href)
          return {
            tagName: 'a',
          };
        if (
          attribs.href.includes('/content/') &&
          ['webpage2-link', 'webpage2-ext-link'].includes(attribs.rel)
        ) {
          return {
            tagName: 'a',
            attribs: {
              class: 'theme-content-link',
              'data-link': attribs.href,
            },
          };
        }
        return {
          tagName: 'a',
          attribs: {
            href: attribs.href,
            target:
              attribs.href.includes(window.location.origin) || attribs.href.startsWith('/content/')
                ? '_self'
                : '_blank',
            class: attribs.rel === 'term' ? 'theme-content-begrip' : 'theme-content-link',
            rel: attribs.rel ? attribs.rel : null,
          },
        };
      },
      br: () => ({
        tagName: 'br',
      }),
    },
  },
});

export default getCompilerOptions;
