import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTrainingsViewModel,
  getOrderBasedOnFilters,
  getInitialFilters,
  isFilterValueDifferentFromDefault,
  getDefaultFilters,
  extendFiltersWithUserViewOptions,
  checkIfFiltersAreOutsideOfViewOptions,
} from '@src/helpers/trainingsHelper';
import { searchTrainings, loadMoreTrainings } from '@src/reduxStore/trainings/trainingsThunks';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { curriculumSitesLogic } from '@kathondvla/shared-logic';
import { cleanUpObjectEmptyValues } from '@src/helpers/utils';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';

const FILTERS_TO_EXCLUDE_FOR_COUNTER = ['orderby', 'limit', 'q', 'coverage', 'distanceInKm', 'lon'];

/**
 * This function receives a filters object and counts the number of applied filters (excluding some that doesn't need to be count (because EARS))
 * @param {} trainingsFilters
 * @returns {number}
 */
const getFiltersCount = (trainingsFilters) =>
  Object.entries(cleanUpObjectEmptyValues(trainingsFilters)).reduce((prev, [key, value]) => {
    if (FILTERS_TO_EXCLUDE_FOR_COUNTER.includes(key)) return prev;

    if (Array.isArray(value)) {
      return prev + value.length;
    }

    // we only want to count date filters once
    return key === 'dateTo' && trainingsFilters.dateFrom ? prev : prev + 1;
  }, 0);

/**
 * This function received a filters object and updates the url
 * @param {*} trainingsFilters
 */
const updateLocationSearchParams = (trainingsFilters = {}) => {
  const newLocationUrl = new URL(window.location.href.split('?')[0]);
  const previousSearch = new URL(window.location.href).toString();

  Object.entries(trainingsFilters).forEach(([key, value]) => {
    let newValue = value;
    if (newValue) {
      if (Array.isArray(newValue)) {
        if (key === 'curricula') {
          newValue = curriculumSitesLogic.encodeThemes(newValue);
        } else {
          newValue = newValue.join(',');
        }
      }
      if (newValue !== '' && isFilterValueDifferentFromDefault(key, newValue))
        newLocationUrl.searchParams.set(key, encodeURIComponent(newValue));
    }
  });

  if (newLocationUrl.toString() !== previousSearch) {
    window.history.pushState({}, '', newLocationUrl);
  }
};

const useTrainings = () => {
  const dispatch = useDispatch();
  const viewOptions = useSelector(selectUserViewOptions);
  const [trainingsFilters, setTrainingsFilters] = useState(getInitialFilters());

  // handle popstate event (go back and forward in browser)
  useEffect(() => {
    const handleChange = () => {
      window.setTimeout(() => {
        getAngularService('$state').reload();
      }, 0);
    };
    window.addEventListener('popstate', handleChange);
    return () => {
      window.removeEventListener('popstate', handleChange);
    };
  }, []);

  /**
   * This useEffect will update the trainingFilters according to the viewOptions (when they change) in case the user did not perform any search
   * That is mostly needed when the user lands for the first time on the trainings
   */
  useEffect(() => {
    const noSearchPerformed = window.location.search.length === 0;
    if (viewOptions && noSearchPerformed)
      setTrainingsFilters((prevState) => extendFiltersWithUserViewOptions(prevState, viewOptions));
  }, [viewOptions]);

  /**
   * Updates all trainingsFilters at once
   * @param {*} newTrainingsFilters
   */
  const updateAllFilters = (newTrainingsFilters) => {
    setTrainingsFilters(newTrainingsFilters);
  };

  /**
   * Updates an individual filter
   * @param {string} filterKey
   * @param {string} filterValue
   */
  const updateFilter = (filterKey, filterValue) => {
    if (filterKey === 'curricula') {
      setTrainingsFilters((prevState) => ({
        ...prevState,
        menuItem: undefined,
        curricula: filterValue,
      }));
    } else if (filterKey === 'menuItem') {
      setTrainingsFilters((prevState) => ({
        ...prevState,
        menuItem: filterValue,
        curricula: [],
      }));
    } else if (['dateFrom', 'dateTo'].includes(filterKey)) {
      setTrainingsFilters((prevState) => ({
        ...prevState,
        [filterKey]: filterValue ? new Date(+filterValue).getTime() : undefined,
      }));
    } else if (filterKey === 'distanceInKm') {
      // LEGACY: Apparently we only update the "distaneInKm" filter if we have latitude and longitude
      if (trainingsFilters.lat && trainingsFilters.lon) {
        setTrainingsFilters((prevState) => ({
          ...prevState,
          [filterKey]: filterValue,
        }));
      }
    } else {
      setTrainingsFilters((prevState) => ({
        ...prevState,
        [filterKey]: filterKey === 'limit' ? +filterValue : filterValue,
      }));
    }
  };

  /**
   * the viewModel transforms the user filters in the data the components needs for rendering it on the trainings filters.
   */
  const viewModel = useMemo(
    () =>
      getTrainingsViewModel({
        ...trainingsFilters,
        /**
         * According to EARS depending on the user selected filters the sort value needs to change.
         * It will only apply if the user did not specifically selected one sort options
         */
        ...(trainingsFilters.orderby === null
          ? { orderby: getOrderBasedOnFilters(trainingsFilters) }
          : {}),
      }),
    [trainingsFilters]
  );

  const isOutsideViewOptions = useMemo(
    () => checkIfFiltersAreOutsideOfViewOptions(viewOptions, viewModel),
    [viewOptions, viewModel]
  );

  /**
   * This method is used to update the checkbox values on the trainingFilters.
   * It will handle the case of the "all" checkbox and the normal filter
   * @param {{checkboxId: string, checkboxKey: string, checkboxValue: string}} checkboxData
   */
  const updateCheckboxFilter = (checkboxData) => {
    const { checkboxId, checkboxKey, checkboxValue } = checkboxData;
    let newCheckboxValue;
    if (checkboxKey === 'alle') {
      newCheckboxValue = checkboxValue ? viewModel[checkboxId].map((el) => el.key) : [];
    } else {
      const filterCurrentValues = trainingsFilters[checkboxId];
      newCheckboxValue = filterCurrentValues.includes(checkboxKey)
        ? filterCurrentValues.filter((val) => val !== checkboxKey)
        : [...filterCurrentValues, checkboxKey];
    }
    updateFilter(checkboxId, newCheckboxValue);
  };

  /**
   * This function will extend the current filters with the ones that could potentially receive as args and will:
   *  - update the url to reflect the new filters
   *  - perform a new search with those new filters
   *  - move the user back to the top of the page
   * The idea of starting using the "overwrittenTrainingsFilters" argument is because there is some cases where we want to update the whole filters object at once.
   * @param {*} overwrittenTrainingsFilters
   */
  const dispatchSearch = (overwrittenTrainingsFilters = {}) => {
    const filters = { ...trainingsFilters, ...overwrittenTrainingsFilters };

    // if we are overwritten the filters we need to update all of them at once
    if (Object.keys(overwrittenTrainingsFilters).length) {
      updateAllFilters(filters);
    }

    updateLocationSearchParams(filters);
    dispatch(searchTrainings(filters));
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 100);
  };

  const dispatchLoadMore = (offset) => {
    if (offset > 0) {
      dispatch(loadMoreTrainings({ ...trainingsFilters, offset }));
    }
  };

  const dispatchOrderUpdate = (orderby) => {
    dispatchSearch({ orderby });
  };

  const resetFiltersToViewOptions = () =>
    setTrainingsFilters(extendFiltersWithUserViewOptions(getDefaultFilters(), viewOptions));

  return {
    exportTrainingsFilters: trainingsFilters, // this should exclusively be used for exporting trainings
    updateFilter,
    updateCheckboxFilter,
    dispatchSearch,
    dispatchLoadMore,
    dispatchOrderUpdate,
    viewModel,
    trainingsFiltersCnt: getFiltersCount(trainingsFilters),
    resetFiltersToViewOptions,
    isOutsideViewOptions,
  };
};

export default useTrainings;
