import { getStudyProgrammes, getProThemes, getReferenceFrame } from './contentDataAccess';
import contentState from './contentState';

const retrieveStudyProgrammes = () => async (dispatch, getState) => {
  const proThemes = getState().content?.proThemes || [];
  if (proThemes.length) {
    const studyProgrammes = await getStudyProgrammes(proThemes);
    dispatch(contentState.actions.setStudyProgrammes(studyProgrammes));
  }
};

export const retrieveProThemes = () => async (dispatch) => {
  const proThemes = await getProThemes();
  dispatch(contentState.actions.setProThemes(proThemes));
  dispatch(retrieveStudyProgrammes());
};

export const retrieveReferenceFrame = () => async (dispatch) => {
  const primaryRefFrame = await getReferenceFrame().then((referenceFrameResp) =>
    referenceFrameResp.map((item) => ({ ...item, href: item.$$meta.permalink }))
  );

  dispatch(contentState.actions.setPrimaryRefFrame(primaryRefFrame));
};

export const init = () => async (dispatch) => {
  console.log('initialising content thunks');
  dispatch(retrieveReferenceFrame());
  dispatch(retrieveProThemes());
};
