import { logoutCurrentUser, getLoginUrl } from '@src/helpers/userHelper';
import { acceptCookies, deleteCookie, setCookie, showCookieModal } from '@helpers/cookieHelper';
import uiState from '@src/reduxStore/ui/uiState';
import { startPreview, stopPreview } from '@src/helpers/previewHelper';
import { isACrawler } from '@src/app/utils';
const sanitizeHtml = require('sanitize-html');
import { analyticCookiesAccepted } from '@helpers/cookieHelper';
import { default as trackPiwikEvent } from '@src/reduxStore/analytics/analyticsThunks';
import { removePostMessageListener } from '@src/helpers/postMessageHelper';
import { initializePiwikSubscribers } from '@src/app/piwikManager';
import { enableAllAnalytics, disableAllAnalytics } from '@src/helpers/analyticsConfigHelper';
import { getPageShareableImage } from '@src/helpers/themeHelper';

class appRoot {
  constructor(
    settings,
    $scope,
    $location,
    $cookies,
    $timeout,
    ngMeta,
    $window,
    navigationService,
    $state,
    constants,
    routerService,
    kovSentry,
    $ngRedux
  ) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.$location = $location;
    this.$cookies = $cookies;
    this.$timeout = $timeout;
    this.navigationService = navigationService;
    this.routerService = routerService;
    this.$state = $state;
    this.constants = constants;
    this.$window = $window;
    this.preview = false;
    this.ngMeta = ngMeta;
    this.scrollPos = 0;
    this.kovSentry = kovSentry;
    this.$ngRedux = $ngRedux;
  }

  $onInit = async () => {
    this.addEventListeners();
    initializePiwikSubscribers();
    enableAllAnalytics();
    /* --- until user logic ---*/
    this.kovSentry.endLog('load Pro Homepage');
    this.openCookieModal = showCookieModal();

    if (this.$location.search().preview) {
      startPreview();
      this.preview = true;
      console.log('======= PREVIEW MODE ENABLED ========');
    } else {
      stopPreview();
    }

    if (this.$location.search().redactie) {
      console.log('======= REDACTIE MODE ENABLED ========');
    }

    // the following method is used to clean up potential cookies the user could have on his computer
    // that we are not using anymore. Theoretically this method should only be here during x amount of time (like 1 year or so)
    this.cleanNotUsedCookies();
    this.appLoaded = true;

    this.unsubscribeFromLocalRedactieFlag = this.$ngRedux.connect((state) => ({
      showWrongPostMessageOriginAlert: state.suggestions.showWrongPostMessageOriginAlert,
    }))(this);
  };

  addEventListeners() {
    let titlePath;

    this.$scope.$on('applyChanges'),
      () => {
        this.$scope.$applyAsync();
      };

    this.$scope.$on('showOutOfViewOptions', (_event, show) => {
      if (isACrawler()) {
        this.showOutOfViewOptions = false; // don't show this for crawler.
      } else {
        this.showOutOfViewOptions = show;
      }
    });

    this.$scope.$on('showTrainingsMenuBar', (_event, show) => {
      this.showTrainingsMenuBar = show;
    });

    this.$scope.$on('setPageSettings', (_event, theme) => {
      // apparently the following code is only used to update the quick navigation and show the "current place on the website" on it when opening it.
      // So, if you go to Leeftoch and then you open the quick navigation you will see that part of the quick navigation on the screen
      this.theme = theme;
    });

    this.$scope.$on('wsCookieConsent:cookieConsentAccepted', () => {
      this.$cookies.put('cookieConsentAccepted', true);
      this.showCookieConsentMsg = false;
    });

    this.$scope.$on('wsCookieConsent:acceptCustom', () => {
      console.log('data', data);
      // this.$cookies.put('cookieConsentAccepted', true);
      this.showCookieConsentMsg = false;
    });

    this.$scope.$on('dynamicMetaDataUpdated', (_event, page) => {
      const emptyTag = '&nbsp;';

      const pageTitle = page?.title
        ? sanitizeHtml(page.title, { allowedTags: [], allowedAttributes: {} })
        : emptyTag;

      this.ngMeta.setTitle(pageTitle);

      this.ngMeta.setTag(
        'description',
        page?.description
          ? sanitizeHtml(page.description || '', { allowedTags: [], allowedAttributes: {} })
          : emptyTag
      );
      const image = getPageShareableImage(page);

      this.ngMeta.setTag('image', image.href);
      if (image.dimension) {
        this.ngMeta.setTag('imageWidth', image.dimension.w);
        this.ngMeta.setTag('imageHeight', image.dimension.h);
      }
      window.prerenderReady = true;
    });

    this.$scope.$on('appHome:logout', () => {
      this.logout();
    });

    this.$scope.$on('appHome:login', () => {
      this.login();
    });

    this.$scope.$on('appHome:editProfile', () => {
      this.$window.open(this.settings.apisAndUrls.mijn);
    });

    this.$scope.$on('appHome:nascholing', () => {
      this.$window.open(
        `${this.settings.apisAndUrls.trainings.nascholing}/2020-2021/deelninschroverz.aspx`
      );
    });

    this.$scope.$on('appHome:nieuwsbrieven', () => {
      this.$ngRedux.dispatch(trackPiwikEvent('start_newsletter'));
      this.$window.open(`${this.settings.apisAndUrls.mijn}/#!/nieuwsbrieven`);
    });

    this.$scope.$on('appHome:leerpaden', () => {
      this.$window.open('https://leeromgeving.katholiekonderwijs.vlaanderen/slides/all?my=1');
    });

    this.$scope.$on('appHome:reloadViewOptions', () => {
      setTimeout(() => this.$state.reload());
    });

    this.$scope.$on('appHome:cookies', () => {
      this.openCookieModal = true;
      document.dispatchEvent(
        new CustomEvent('openKovCookieModal', { bubbles: true, composed: true })
      );
    });

    this.$scope.$on('searchButtonActive', (ev, value) => {
      this.searchButtonActive = value;
    });

    this.$scope.$on('cfpLoadingBar:completed', () => {
      this.$timeout.cancel(this.loadingTimeout);
      this.completedTimeout = this.$timeout(() => {
        this.loading = false;
      }, 500);
    });

    this.$scope.$on('cfpLoadingBar:loading', () => {
      this.$timeout.cancel(this.completedTimeout);
      this.loadingTimeout = this.$timeout(() => {
        this.loading = true;
      }, 200);
    });
  }

  /**
   * This method is used to clean cookies that we don't use. It won't make any harm but theoretically
   * it could be deleted after an amount of time, like 1 year or so..
   */
  cleanNotUsedCookies = () => {
    // removing analytics cookies
    const analyticsCookies = document.cookie.match(/(_ga|_gid)(.*?)(?==)(?=.+?(;|$))/g);

    if (analyticsCookies?.length)
      analyticsCookies.forEach((cookieName) =>
        deleteCookie(cookieName, '.katholiekonderwijs.vlaanderen')
      );

    // removing miniGuidedCompleted cookie
    const miniGuidedCompletedCookies = document.cookie.match(
      /(miniGuidedCompleted)(.+?)(?==)(?=.+?(;|$))/g
    );

    if (miniGuidedCompletedCookies?.length)
      miniGuidedCompletedCookies.forEach((cookieName) => deleteCookie(cookieName));
  };

  openSidebar = () => {
    this.$ngRedux.dispatch(uiState.actions.setIsSideBarOpen(true));
  };

  setCookies = (cookies) => {
    Object.keys(cookies).forEach((key) => setCookie(key, cookies[key]));
    if (!analyticCookiesAccepted()) {
      disableAllAnalytics();
    } else {
      // This is triggered in two situations:
      // 1) user accepts analytics consent the first time he is entering PRO
      // 2) user revoked consent at some point and accepts tracking again
      enableAllAnalytics();
    }
    acceptCookies();
    this.openCookieModal = false;
    this.reloadViewOptions(true);
  };

  reloadViewOptions = (forceApply) => {
    this.$scope.$emit('appHome:reloadViewOptions');
    if (forceApply) {
      this.$scope.$apply();
    }
  };

  logout() {
    this.$window.location.href = logoutCurrentUser();
  }

  login() {
    this.$window.location.href = getLoginUrl();
  }

  goToHome = (forceApply) => {
    const homepage = this.navigationService.getHomepage();
    if (homepage) {
      this.toggleMenuOpened = false;
      this.$location.path(homepage.path).search({});
    }
    if (forceApply) {
      this.$scope.$apply();
    }
  };

  goToSearch = (keyword) => {
    const url = new URL(window.location);
    if (url.pathname === '/zoeken') {
      this.$location.search('q', keyword);
      this.$timeout(() => {
        // timeout is here for when you already are on the search page for which we configured router to not rerender when we change the url params
        // this.$state.go with reload and notify true wil reinitialise search but search listens to current url so the q: which you put in here are immediately overwritten
        // so we still need the above $location.path to successfully change the url with the new searchTerm, but without timeout search component will still read the old url
        this.$state.go('search', { q: keyword }, { reload: true, notify: true });
      }, 0);
    } else {
      this.$location.path('/zoeken').search({
        q: keyword,
      });
    }
    this.$scope.$apply();
  };

  dispatchEvent = (event, forceApply) => {
    this.$scope.$emit(event);
    if (forceApply) {
      this.$scope.$apply();
    }
  };

  stopPreview = () => {
    stopPreview();
    this.preview = false;
    console.log('======= PREVIEW MODE DISABLED ========');
    this.$scope.$apply();
  };

  stopRedactie = () => {
    removePostMessageListener();
    this.unsubscribeFromLocalRedactieFlag();
    this.showWrongPostMessageOriginAlert = false;
    console.log('======= REDACTIE MODE DISABLED ========');
    this.$scope.$apply();
  };

  //check the user out of view options bar
  showViewOptions = () => {
    this.showOutOfViewOptions = false;
    this.$scope.$apply();
  };
}

export default {
  template: require('./appRoot.html'),
  controllerAs: 'ctrl',
  controller: appRoot,
};
