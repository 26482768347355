// eslint-disable-next-line import/no-dynamic-require
module.exports = {
  angularModuleName: 'pro',
  projectName: 'pro',
  projectVersion: 'themeServiceRefactor_v11',
  environment: 'test',
  release: 'proplus__themeServiceRefactor_v11__2024-03-07T10:46:10.719Z',
  projectDescription: 'The pro website of Katholiek Onderwijs Vlaanderen.',
  piwikAnalytics: {
    enabled: true,
    token: 'a7bfb5b5-b5b1-4789-bd80-7db893637d92',
  },
  apisAndUrls: {
    api: 'https://testapi.katholiekonderwijs.vlaanderen',
    privateStatesApi: 'https://testprivatestate-api.katholiekonderwijs.vlaanderen',
    searchApi: 'https://vsko-search-api-test.herokuapp.com',
    cachedApi: 'https://testcached-api.katholiekonderwijs.vlaanderen',
    contentApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    websitesApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    cachedContentApi: 'https://testcached-api.katholiekonderwijs.vlaanderen',
    cachedWebsitesApi: 'https://testcached-api.katholiekonderwijs.vlaanderen',
    securityApi: 'https://testbeveiliging-nodejs.katholiekonderwijs.vlaanderen',
    trainings: {
      nascholing: 'https://nascholing.be/',
      schoolYear: '/2020-2021/',
    },
    mijn: 'https://testmijn.katholiekonderwijs.vlaanderen',
    www: 'https://testwww.katholiekonderwijs.vlaanderen',
    downloadUrl: 'https://testproplus.katholiekonderwijs.vlaanderen/download',
    proUrl: 'https://testproplus.katholiekonderwijs.vlaanderen',
  },
  cookies: {
    standaloneModalWidgetEndpoint:
      'https://teststandalone-cookie-modal.katholiekonderwijs.vlaanderen/widget.js',
    contentHref:
      'https://vsko-content-api-html-test-b831e2aa484d.herokuapp.com/content/ec11efd0-6556-458b-8e2c-8e4c2d8b8b48/html',
    essentialId: '015afa35-b939-456c-b5f6-abacbc56ed6f',
    analyticId: 'd5c51eb1-4954-40c3-8f8b-dc5997139f47',
    socialMediaId: '100bca36-6c24-40b6-9fda-8f553f38a763',
  },
  logging: {
    sentry: {
      enabled: true,
      url: 'https://367ed3c8d762450a865de15c15a77f5f@o268751.ingest.sentry.io/5640658',
      sentryPerformanceSampleRate: 0.03,
      fetchInterceptor: true,
    },
  },
  prerender: {
    token: 'XviHbBzWrXNJwKRYTvpa',
  },
  trustedOrigins: ['*'],
  oauth: require('./vsko-oauth-configuration-stripped.json'),
  proHomepageHref: '/content/c63847ea-be2e-44ec-abf1-ead0e4bac37f',
  proGlobalDatabase: {
    href: '/content/d62375e7-9f33-4823-aca3-0b7a8c2d485c',
    url: '/databank-inspirerend-materiaal-so',
    title: 'Inspirerend materiaal',
    databaseTypeThemehref: '/content/898b34bb-c171-42ca-8b5d-2a2443fbf7f6',
  },
  website: '/web/sites/70b12dcc-724b-46b8-9ab9-b8ecdf35a0ad',
  allowNotPublished: false,
  allowFutureTrainings: false,
  component: '/security/components/pro',
  defaultColor: '#0A82BE',
  defaultIcon:
    'https://cached-api.katholiekonderwijs.vlaanderen/content/28ec313a-7869-4ae6-a8ef-2bf23adaa708/attachments/veldicoontjes-08.svg',
  newsHash: '/content/hashes/6c48604b-1549-410c-9c81-5467f239800d',
  newsCacheTime: 60,
  debounceTime: 500,
};