// BOOTSTRAP (kind of ugly, because it expects jQuery)
window.$ = require('jquery');

window.jQuery = window.$;

const settings = require('@src/config/settings.js');
const app = require('./app.js');
require('./oauthInterceptor').init(app);
const utils = require('./utils.js');
const { loaderEnd } = require('@src/helpers/loaderHelper.js');
const { themeActions } = require('@src/reduxStore/theme/themeSlice.js');
const store = require('../reduxStore/store.js').default;

const { injectAccessStore } = require('@src/helpers/accessHelper');
const { injectTrainingsStore } = require('@src/helpers/trainingsHelper');
injectAccessStore(store);
injectTrainingsStore(store);

require('@src/module/directives');
require('@src/module/reactMigrationUtils/bridges');
require('@src/filters');
require('@src/components/index');

// also add the window.settings as a constant to our angular module
app.constant('settings', settings);
app.constant('utils', utils);
app.constant('constants', require('../components/constants.js'));

app.config(require('./config.js')).config([
  '$compileProvider',
  function ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|javascript):/);
  },
]);

// app.value('$routerRootComponent', 'appRoot');

// Allow inclusion of all urls
app.config([
  '$sceDelegateProvider',
  function ($sceDelegateProvider) {
    // TODO: only kathonvla
    $sceDelegateProvider.resourceUrlWhitelist(['**']);
  },
]);

app.run([
  '$q',
  function ($q) {
    // Use Angular's Q object as Promise. This is needed to make async/await work
    // properly with the UI. See http://stackoverflow.com/a/41825004/536
    window.Promise = $q;
  },
]);

app.filter(
  'startFrom',
  () =>
    function (input, start) {
      if (input) {
        start = +start; // parse to int
        return input.slice(start);
      }
      return [];
    }
);

// Configuation that has to do with router
app.run([
  '$transitions',
  '$state',
  '$window',
  function ($transitions, $state, $window) {
    // Show 404 when state not found
    $transitions.onError({}, ($transition) => {
      if ($transition.error().type !== 5) {
        console.warn($transition.error());
        // $state.go('404');
      }
    });

    // Scroll to page top only on non dynamic transitions
    $transitions.onFinish({}, ($transition) => {
      if (!$transition.dynamic()) {
        $window.scrollTo(0, 0);
      }
    });
  },
]);

// Configuation that has to do with router
app.run([
  'navigationService',
  'kovSentry',
  function (navigationService, kovSentry) {
    kovSentry.startLog('load Pro Homepage');
    kovSentry.startLog('load Pro');
    // console.time('loadPROHome');
    navigationService.init();
  },
]);

app.run(($transitions) => {
  $transitions.onSuccess({}, (transition) => {
    if (transition.from().$$state().includes.theme && !transition.to().$$state().includes.theme) {
      store.dispatch(themeActions.clearThemeData());
      loaderEnd();
    }
  });
});

app.run([
  'ngMeta',
  function (ngMeta) {
    ngMeta.init();
  },
]);

app.config(($ngReduxProvider) => {
  'ngInject';
  $ngReduxProvider.provideStore(store);
});

console.log(
  '  _  __     _   _           _ _      _       ___            _                    _  _      __     ___                       _                     \n' +
    ' | |/ /__ _| |_| |__   ___ | (_) ___| | __  / _ \\ _ __   __| | ___ _ ____      _(_)(_)___  \\ \\   / / | __ _  __ _ _ __   __| | ___ _ __ ___ _ __  \n' +
    " | ' // _` | __| '_ \\ / _ \\| | |/ _ \\ |/ / | | | | '_ \\ / _` |/ _ \\ '__\\ \\ /\\ / / || / __|  \\ \\ / /| |/ _` |/ _` | '_ \\ / _` |/ _ \\ '__/ _ \\ '_ \\ \n" +
    ' | . \\ (_| | |_| | | | (_) | | |  __/   <  | |_| | | | | (_| |  __/ |   \\ V  V /| || \\__ \\   \\ V / | | (_| | (_| | | | | (_| |  __/ | |  __/ | | |\n' +
    ' |_|\\_\\__,_|\\__|_| |_|\\___/|_|_|\\___|_|\\_\\  \\___/|_| |_|\\__,_|\\___|_|    \\_/\\_/ |_|/ |___/    \\_/  |_|\\__,_|\\__,_|_| |_|\\__,_|\\___|_|  \\___|_| |_|\n' +
    '                                                                                 |__/                                                             '
);
console.log('### Software version: #{release_id} ###');

module.exports = app;
