import { transformViewOptionsToParams, useSearchApi } from '@components/searchApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import { findFaqGroupAncestors } from '@components/theme/themeFaq/helpers';
import { useSelector } from 'react-redux';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { isPreview } from '@src/helpers/previewHelper';
import { SEARCH_API_LIMIT_MAX } from '@src/components/constants';

const useFaq = (
  page,
  searchedTerm,
  curricula,
  selectedFaqGroupHref,
  onUpdateStateFromUrl,
  scrollTo
) => {
  const [faqGroups, setFaqGroups] = useState(undefined);
  const viewOptions = useSelector(selectUserViewOptions);

  const previewData = useMemo(
    () =>
      // we modify the local data to be able to be parsed as if the results came from the search-api
      page?.children
        .filter((child) => child.children)
        .flatMap((child) => child.children)
        .map((child) => ({
          ...child,
          contentType: child.type,
          content: child.children?.map((contentItem) => {
            switch (contentItem.type) {
              case 'PARAGRAPH':
                return {
                  text: contentItem.$$html,
                  type: contentItem.type,
                };
              case 'VIDEO':
                return {
                  video: {
                    playbackUrl: contentItem.videoPlayback,
                  },
                  type: contentItem.type,
                };
              default:
                return contentItem;
            }
          }),
          ancestors: [
            [{ ...child.parent, contentType: child.parent.type, permalink: child.parent.href }],
          ],
        })),
    [page?.children]
  );

  const { getSearchResults, searchResults } = useSearchApi({
    previewDataToUse: isPreview() ? previewData : null,
  });

  const [filteredResults, setFilteredResults] = useState([]);

  const initialFaqGroups = useMemo(() => {
    if (!page || !page.children) return null;
    return [
      {
        title: 'Alle',
        href: page.href,
      },
      ...page.children
        .filter((o) => o.type === 'FAQ_GROUP' && o.visible)
        .map((group) => ({
          title: group.title,
          href: group.href,
        })),
    ];
  }, [page]);

  const faqGroupAll = useMemo(
    () => initialFaqGroups.find((a) => a.title === 'Alle'),
    [initialFaqGroups]
  );

  const isFaqGroupAllSelected = useMemo(
    () => faqGroupAll.href === selectedFaqGroupHref,
    [faqGroupAll, selectedFaqGroupHref]
  );

  const paramsStateObj = useMemo(
    () => [
      {
        key: 'q',
        state: searchedTerm,
        updateState: (value) =>
          onUpdateStateFromUrl({
            key: 'q',
            value,
          }),
      },
      {
        key: 'curricula',
        state: curricula,
        updateState: (value) =>
          onUpdateStateFromUrl({
            key: 'curricula',
            value,
          }),
      },
      {
        key: 'faqGroup',
        state: selectedFaqGroupHref,
        updateState: (value) =>
          onUpdateStateFromUrl({
            key: 'faqGroup',
            value,
          }),
      },
      {
        key: 'scrollTo',
        state: scrollTo,
        updateState: (value) =>
          onUpdateStateFromUrl({
            key: 'scrollTo',
            value,
          }),
      },
    ],
    [onUpdateStateFromUrl, searchedTerm, curricula, selectedFaqGroupHref, scrollTo]
  );

  const { updateUrlSearchParams, DEPRECATEDisStateChangedByUrl } = useUrlSearchParams(
    paramsStateObj,
    initialFaqGroups !== null
  );

  useEffect(() => {
    updateUrlSearchParams();
  }, [paramsStateObj, updateUrlSearchParams]);

  useEffect(() => {
    if (!DEPRECATEDisStateChangedByUrl) return;

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      if (key === 'q') {
        onUpdateStateFromUrl({
          key: 'q',
          value,
        });
      } else if (key === 'faqGroup') {
        if (initialFaqGroups.map((g) => g.href).includes(value)) {
          onUpdateStateFromUrl({
            key: 'faqGroup',
            value,
          });
        }
      } else if (key === 'scrollTo') {
        onUpdateStateFromUrl({
          key: 'scrollTo',
          value,
        });
      }
    });
  }, [initialFaqGroups, DEPRECATEDisStateChangedByUrl, onUpdateStateFromUrl]);

  const updateFaqGroupCounters = useCallback(
    (apiResponse) => {
      if (!apiResponse) return;

      const updatedFaqGroups = initialFaqGroups.map((iniFaqGroup) => ({
        ...iniFaqGroup,
        count: 0,
      }));

      const resultsWithContent = apiResponse.results.filter((result) => result.content != null);

      // setting up the global results count to the "Alle" FaqGroup (element 0)
      updatedFaqGroups[0].count = resultsWithContent.length;

      resultsWithContent.forEach((searchResult) => {
        const ancestors = findFaqGroupAncestors(searchResult, faqGroupAll.href);
        if (ancestors.length > 0) {
          ancestors.forEach((a) => {
            const index = updatedFaqGroups.findIndex((group) => group.href === a.permalink);
            updatedFaqGroups[index].count += 1;
          });
        }
      });
      setFaqGroups(updatedFaqGroups);
    },
    [faqGroupAll.href, initialFaqGroups]
  );

  const doSearch = useCallback(async () => {
    if (!viewOptions) return;

    const searchApiParams = {
      types: 'pro_faq_question',
      limit: SEARCH_API_LIMIT_MAX,
      expand: 'FULL',
      noThemeOrThemeRoots: curricula.join(','),
      q: searchedTerm,
      includedIn: faqGroupAll.href,
      ...transformViewOptionsToParams(page, viewOptions),
    };

    const searchAPIResponse = await getSearchResults(searchApiParams);
    updateFaqGroupCounters(searchAPIResponse);
    // once the results are fetched we remove the scrollTo attribute from the url
    // because in the next click from the user it should not apply
    onUpdateStateFromUrl({
      key: 'scrollTo',
      value: '',
    });
  }, [
    getSearchResults,
    searchedTerm,
    faqGroupAll,
    curricula,
    updateFaqGroupCounters,
    onUpdateStateFromUrl,
    viewOptions,
  ]);

  useEffect(() => {
    if (isFaqGroupAllSelected) {
      setFilteredResults(searchResults);
    } else {
      const newResults = [];
      searchResults.forEach((searchResult) => {
        const ancestors = findFaqGroupAncestors(searchResult, faqGroupAll.href);
        ancestors.forEach((a) => {
          if (a?.permalink === selectedFaqGroupHref) newResults.push(searchResult);
        });
      });
      setFilteredResults(newResults);
    }
  }, [faqGroupAll.href, isFaqGroupAllSelected, searchResults, selectedFaqGroupHref]);

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  return {
    faqGroups,
    filteredResults,
    updateUrlSearchParams,
  };
};

export default useFaq;
