import { createSelector } from '@reduxjs/toolkit';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { buildThemeFromItemList } from '@src/helpers/themeHelper';
import { checkIfOutOfViewOptions } from '@src/helpers/contextFilterHelper';
import { getSitemapItemFromSourceOrPath } from '@src/services/sitemapService';
import { getRedactieItems } from '@src/reduxStore/suggestions/suggestionsSelectors';
import { arrayToObject, createThemeSearchIndex, flattenTree } from '@src/app/utils';
import {
  selectFlatItems,
  selectLatestClusterChildren,
} from '@src/reduxStore/navigation/navigationSelectors';
import { mapTreeRecursive } from '@src/components/theme/templates/helpers/helpers';
import { selectPrimaryRefFrame } from '../content/contentSelectors';

export const selectCurrentThemeHref = (state) => state.theme.currentThemeHref;

export const getCurrentProTheme = createSelector(
  [selectCurrentThemeHref, (state) => state.theme.content],
  (themeHref, content) => {
    if (!themeHref) return null;
    const proTheme = content[themeHref];
    if (!proTheme || !proTheme.isProTheme) {
      return null;
    }
    if (proTheme.themes && proTheme.themes.length > 1) {
      console.warn(
        `pro theme ${proTheme.title} [${proTheme.href}] has multiple themes (reference frame items): ${proTheme.themes}`
      );
    }
    return {
      href: proTheme.href,
      title: proTheme.title,
      referenceFrameItem: proTheme.themes?.map((rfiHref) => ({
        href: rfiHref,
        title: content[rfiHref]?.title,
      })),
    };
  }
);

export const getCurrentProThemeHref = (state) => {
  const proTheme = getCurrentProTheme(state);
  return proTheme?.referenceFrameItem[0]?.href;
};

export const getCurricula = (state) => state.theme.curricula;

export const selectCurriculumThemes = createSelector(
  [selectPrimaryRefFrame],
  (primaryReferenceFrame) =>
    primaryReferenceFrame.filter((content) =>
      ['CURRICULUM_THEME', 'COMMONS_STUDIEGEBIED', 'VAK', 'THEME'].includes(content.type)
    )
);

export const selectRawCurrentThemeItems = (state) => state.theme.rawCurrentThemeItems;
export const selectTreeLoadingStatus = (state) => state.theme.treeLoadingStatus;

export const selectCurrentThemeRootHref = createSelector(
  [selectCurrentThemeHref],
  (currentThemeHref) => getSitemapItemFromSourceOrPath(currentThemeHref)?.rootHref
);

export const selectPreVisitedMenuItem = createSelector(
  [selectLatestClusterChildren, selectCurrentThemeRootHref],
  (latestClusterChildren, rootHref) =>
    latestClusterChildren?.find((child) => child.references.includes(rootHref))
);

/**
 * This selector will build the original themeTree with the raw data or redactieItems (in case of preview)
 * It will take into account the viewOptions to build it
 */
export const selectCurrentThemeTree = createSelector(
  [
    selectCurrentThemeRootHref,
    selectRawCurrentThemeItems,
    getRedactieItems,
    selectFlatItems,
    selectPreVisitedMenuItem,
    selectUserViewOptions,
  ],
  (themeRootHref, items, redactieItems, navTreeItems, latestClusterChildren, viewOptions) => {
    const redactieItemsArrayToObject = redactieItems && arrayToObject(redactieItems);
    // this is done to not use the redactie items, even if we have them,
    // when the item we are trying to render is not there.
    // This is used when we are previewing the homePage (and the different items we
    // can navigate there) and we end up clicking a ProTheme.
    // ProThemes are not defined on the homepage ProTheme in redactie so before
    // these changes it was breaking at this point because we couldn't find it on
    // the redactie items
    if (redactieItemsArrayToObject?.[themeRootHref]) {
      return buildThemeFromItemList(
        themeRootHref,
        redactieItemsArrayToObject,
        navTreeItems,
        latestClusterChildren,
        viewOptions
      );
    }
    return buildThemeFromItemList(
      themeRootHref,
      items,
      navTreeItems,
      latestClusterChildren,
      viewOptions
    );
  }
);

export const selectFlatCurrentThemeTree = createSelector(
  [selectCurrentThemeTree],
  (currentThemeTree) => flattenTree(currentThemeTree, false)
);

/**
 * This selector returns the currentThemeTree altering the visibility on those nodes that is needed, for instance, when a user tries to go to a ThemeDetail that is fully out of his viewOptions
 */
export const selectCurrentThemeTreeWithVisibilityExceptions = createSelector(
  [
    selectCurrentThemeTree,
    selectUserViewOptions,
    selectFlatCurrentThemeTree,
    (_, itemToRenderHref) => itemToRenderHref,
  ],
  (currentThemeTree, viewOptions, flatCurrentThemeTree, itemToRenderHref) => {
    // we find the element to (probably) modify from the original flatted themeTree
    const itemToRender = flatCurrentThemeTree.find((a) => a.href === itemToRenderHref);
    if (itemToRender && checkIfOutOfViewOptions(itemToRender, viewOptions)) {
      // if we find it and it is "fully" out of the viewOptions we create an array of the item and the children in order to force later the visibility to trye
      return mapTreeRecursive(currentThemeTree, viewOptions, true);
    }
    // we return the modified currentThemeTree
    return mapTreeRecursive(currentThemeTree, viewOptions, false);
  }
);

export const selectFlatCurrentThemeTreeWithVisibilityExceptions = createSelector(
  [selectCurrentThemeTreeWithVisibilityExceptions],
  (currentThemeTree) => flattenTree(currentThemeTree, false)
);

export const selectItemInCurrentThemeTree = createSelector(
  [
    selectFlatCurrentThemeTreeWithVisibilityExceptions,
    (state, itemHrefToSearch) => itemHrefToSearch,
  ],
  (flatCurrentThemeTreeWithVisibilityExceptions, itemHrefToSearch) =>
    flatCurrentThemeTreeWithVisibilityExceptions.find((item) => item.href === itemHrefToSearch)
);

/**
 * This selector adds the searchIndex in case it is needed. Depending on the current item we add it to the item itself or the parent
 * This should disappear soon, this is a legacy case
 */
export const selectItemInCurrentThemeTreeWithSearchIndex = createSelector(
  [(state, itemHrefToSearch) => selectItemInCurrentThemeTree(state, itemHrefToSearch)],
  (itemInCurrentTheme) => {
    if (!itemInCurrentTheme) return undefined;

    const { pageType } = itemInCurrentTheme;
    if (pageType === 'BLOG') {
      const shouldCreateIndex = itemInCurrentTheme.facets?.find(
        (facet) => facet.component === 'FULL_TEXT_SEARCH'
      );
      return {
        ...itemInCurrentTheme,
        ...(shouldCreateIndex
          ? {
              searchIndex: createThemeSearchIndex(itemInCurrentTheme),
            }
          : {}),
      };
    }

    if (pageType === 'BLOG_ITEM') {
      const shouldCreateIndex = itemInCurrentTheme.parent?.facets?.find(
        (facet) => facet.component === 'FULL_TEXT_SEARCH'
      );

      return {
        ...itemInCurrentTheme,
        ...(shouldCreateIndex
          ? {
              parent: {
                ...itemInCurrentTheme.parent,
                searchIndex: createThemeSearchIndex(itemInCurrentTheme.parent),
              },
            }
          : {}),
      };
    }

    return undefined;
  }
);

export default { getCurrentProTheme, getCurricula };
